import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { RouteObserverService } from '@/services/route-observer.service';
import { ToastService } from '@/modules/toast/services/toast.service';
import { AuthService } from '@/services/auth.service';
import { PASSWORD_VALIDATION_PATTERN } from '@/constants';

import { CustomValidators } from '../../validators/CustomValidators';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetComponent implements OnInit, OnDestroy {
  @Output() closeModal = new EventEmitter();
  @Output() openLogin = new EventEmitter();

  private subscriptions = new Subscription();
  private token: string;
  private email: string;

  public submitted = false;
  public loading = false;
  public done = false;

  public resetForm = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.pattern(PASSWORD_VALIDATION_PATTERN)]),
    confirmation: new FormControl('', [Validators.required])
  }, [
    CustomValidators.mustMatch('password', 'confirmation')
  ]);

  constructor(
    private routeObserver: RouteObserverService,
    private toastService: ToastService,
    private authService: AuthService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const { queryParams: { token, email } } = this.routeObserver.currentRoute;
    this.token = token;
    this.email = email;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get password() {
    return this.resetForm.get('password');
  }

  get confirmation() {
    return this.resetForm.get('confirmation');
  }

  handleClose(): void {
    this.closeModal.emit();
  }

  goToLogin(): void {
    this.openLogin.emit();
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.resetForm.valid) {
      this.loading = true;

      const { password, confirmation } = this.resetForm.value;

      this.subscriptions.add(
        this.authService.newPassword(this.token, this.email, password, confirmation).subscribe({
          next: (response) => {
            if (response.errors || !response.success) {
              this.toastService.show({
                title: 'AUTH.RESET.TOAST.TITLE',
                body: 'AUTH.RESET.TOAST.ERROR',
                type: 'error'
              });
            } else {
              this.done = true;
            }

            this.loading = false;
            this.cd.markForCheck();
          }
        })
      );
    }
  }
}
